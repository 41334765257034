.page-footer,
.watermark {
    display: none;
}

@media print {
    .watermark {
        position: fixed;
        display: block;
        top: 50vh;
        z-index: 9;
        width: 50vw;
        page-break-after: always;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .05;
    }

    canvas {
        z-index: 10;
    }

    #notes {
        margin: 75px 0;
        height: calc(100% - 100px);
    }

    .notes {
        background: transparent;
        border-radius: 0;

        .tag {
            background: none;
            color: #000
        }
    }

    div.page-footer {
        position: fixed;
        z-index: 9;
        bottom: 0;
        width: 100%;
        height: 65px;
        font-size: 15px;
        color: #fff;
        background: #0c7676;
        // opacity: 0.5;
        text-align: right;
        page-break-after: always;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .center {
            @include flex-col-center;
            flex: 1;

            .image {
                width: 50px;
            }
        }
    }

}