$sizes: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22;

@each $size in $sizes {

    .fs-#{$size} {
        font-size: #{$size}px !important;
    }
}

.f-bold {
    font-family: 'RB-bold';
}

a {
    text-decoration: none;
    color: #000;
}

.btn-close,
.ant-drawer-close {
    position: absolute;
    left: 5px;
    top: 15px;
}

.modal-header {
    border-right: 5px solid $color-2;
}

.ant-picker-dropdown,
.ant-select-dropdown {
    z-index: 3000 !important;
}

form {
    position: relative;
}

.text-h {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: wrap !important;
}

.img-cover {
    object-fit: cover !important;
}



.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

.icon-input {
    position: relative;
    left: -5px;
    font-size: 22px;
    color: rgb(221, 220, 220);
    padding: 0 0 0 5px;
}

.icon-select {
    display: flex;
    font-size: 22px;
    color: rgb(221, 220, 220);
    position: relative;
    justify-content: center;
    align-content: center;
}

.optional {
    position: relative;
}

.optional:after {
    content: '*';
    font-size: 12px;
    padding: 2px;
    color: #f00;
    position: absolute;
    top: -7px;
    left: 0px;

}

.rate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.notes {
    background-color: white;
    margin-top: 15px;
    border-radius: 15px;
    box-shadow: $shadow;
    overflow: hidden;

    .title {
        font-size: 16px;
        font-family: 'RB-bold';
        padding: 0 15px ;
    }

    .text {
        font-size: 18px;
        padding: 0 15px;
    }

    .tag {
        background-color: $color-2;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
    }
}